/* .ui.dimmer>.content {
    width:100%;
} */

.skip-control {
    line-height: 1.1;
    display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  color:black
}