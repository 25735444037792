@import "../../../../assets/colors.scss";

.pixel-accordion > .pixel-accordion-head {
    cursor: pointer !important;
    border: 1px solid $SG_PAM_DARK_GRAY;
    border-bottom: none;
    background-color: lightgray;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 1em;
    margin: auto;
    transition: 0.3s;
    font-size: 12px;
}
.pixel-accordion > .pixel-accordion-head:hover {
    background-color: gray;
    color: white;
    transition: 0.3s;
}

#filters-reset-btn {
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
}

#filters-reset-btn:hover {
    width: fit-content;
    background: gray;
}
