/**To support fonts in Release Notes Popup **/
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
/* @font-face {
  font-family: 'Lucida Sans Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Lucida Sans Regular'), url('../Fonts/LSANS.woff') format('woff');
}


@font-face {
  font-family: 'Lucida Sans Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Lucida Sans Italic'), url('../Fonts/LSANSI.woff') format('woff');
}


@font-face {
  font-family: 'Lucida Sans Demibold Roman';
  font-style: normal;
  font-weight: normal;
  src: local('Lucida Sans Demibold Roman'), url('../Fonts/LSANSD.woff') format('woff');
}


@font-face {
  font-family: 'Lucida Sans Demibold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Lucida Sans Demibold Italic'), url('../Fonts/LSANSDI.woff') format('woff');
}


@font-face {
  font-family: 'Lucida Sans Typewriter Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Lucida Sans Typewriter Regular'), url('../Fonts/LTYPE.woff') format('woff');
}


@font-face {
  font-family: 'Lucida Sans Unicode Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Lucida Sans Unicode Regular'), url('../Fonts/l_10646.woff') format('woff');
}


@font-face {
  font-family: 'Lucida Sans Typewriter Oblique';
  font-style: normal;
  font-weight: normal;
  src: local('Lucida Sans Typewriter Oblique'), url('../Fonts/LTYPEO.woff') format('woff');
}


@font-face {
  font-family: 'Lucida Sans Typewriter Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Lucida Sans Typewriter Bold'), url('../Fonts/LTYPEB.woff') format('woff');
}


@font-face {
  font-family: 'Lucida Sans Typewriter Bold Oblique';
  font-style: normal;
  font-weight: normal;
  src: local('Lucida Sans Typewriter Bold Oblique'), url('../Fonts/LTYPEBO.woff') format('woff');
} */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.hover-list-content h3 {
  color: #005eb8 !important;
}
.hover-list-content:hover {
  background-color: #d9e9f5;
  cursor: pointer;
}
.hover-list-content.active {
  background-color: #d9e9f5;
}

.modal-close .close.icon,
.close-icon {
  top: -1rem !important;
  right: -1rem !important;
  background-color: #2185d0;
  padding-top: 8px;
  border-radius: 50%;
  opacity: 1;
}
.close-icon {
  position: absolute;
  padding: 0;
  width: 30px;
  height: 30px;
  text-align: center;
  vertical-align: middle;
  line-height: 30px;
  padding-left: 0 !important;
}
.close-icon i {
  width: 30px;
}
.row-hover:hover {
  background-color: rgb(221, 231, 246) !important;
}

.ui.dimmer {
  top: 30px !important;
}
.ui.dimmer.top-0 {
  top: 0 !important;
}

.ui.grid {
  margin-bottom: -1.7rem;
}
.suppressionTableBodyHeight .height265 {
  height: calc(100vh - 265px) !important;
}
.ui.list .item.active .hover-list-content {
  background-color: #d9e9f5;
}
.noData {
  width: 100%;
  color: rgb(175, 171, 171);
  text-align: center;
}

.ui.dimmer.top-0-identificationTable {
  height: calc(100vh - 30px);
}
.release-note-container ul {
  padding-left: 15px;
}
.release-note-container p {
  float: left;
  width: 100%;
  font-family: Helvetica Neue, Arial, Helvetica, sans-serif;
  margin-bottom: 0;
  line-height: 1.42;
}
.ql-font-Lato {
  font-family: "Lato", sans-serif; 
}
.ql-font-Arial {
  font-family: "Arial", sans-serif;
}
.ql-font-helvetica {
  font-family: "Helvetica", sans-serif;
}
.ql-font-verdana {
  font-family: Verdana, sans-serif;
}
.ql-font-georgia {
  font-family: "Georgia", sans-serif;
}
.ql-font-lucida {
  font-family: "Lucida Sans Regular", sans-serif; /* not supported*/
}
.ql-font-oblique {
  font-family: oblique, sans-serif;
  font-style: italic;
}
.ql-font-Ubuntu {
  font-family: "Ubuntu", sans-serif;
} 
.ql-font-Impact {
  font-family: "Impact", sans-serif;
}
.ql-font-TimesNewRoman {
  font-family: "TimesNewRoman", sans-serif;
} /* not supported*/
.containing-two-buttons{
  justify-content: space-between;
}
.containing-one-button {
  justify-content:center;
}
.error-management-modal{
      border: 2px solid #aaa !important;
        border-radius: 0 !important;
}
.common-table thead tr th{
 min-height: 25px;
 padding:5px !important;
 font-size: 14px;
border-radius: 0 !important;
}
.common-table tbody tr td{
  border-top:0 none !important
}
.common-table tbody tr:hover{
  background-color: #dde7f6;
}
@media only screen and (max-width: 767px) {
.ui.modal > .header.modalHeader.paddingRight {
    padding-right: 10px !important;
  }
}