@import "../../../assets/mixins.scss";
@import "../../../assets/colors.scss";

#propreties-preference-component-body-container {
    height: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    #properties-checkbox-div {
        width: fit-content;
        margin: auto;
        padding: 1em;

        .propreties-preference-title h4 {
            margin: 0.5em;
            font-weight: bolder;
        }
    }
}
