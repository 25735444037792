@import "../../../assets/colors.scss";

.pixel-accordion > .pixel-accordion-body {
    max-height: 0px;
    overflow: hidden;
    border: 1px solid $SG_PAM_DARK_GRAY;
    transition: 0.3s;
}
.pixel-accordion {
    .pixel-accordion-expand-icon-more {
        display: inline-block;
        transition: 0.3s;
    }
    .pixel-accordion-expand-icon-less {
        display: none;
        transition: 0.3s;
    }
}

.pixel-accordion.expanded > .pixel-accordion-head {
    // background-color: $SG_PAM_LIGHT_ORANGE;
    transition: 0.3s;
}
.pixel-accordion.expanded > .pixel-accordion-head:hover {
    //  background-color: $SG_PAM_ORANGE;
    transition: 0.3s;
}
.pixel-accordion.expanded > .pixel-accordion-body {
    min-height: 1em;
    max-height: 100em;
    height: 100%;
    transition: 0.3s;
}
.pixel-accordion.expanded {
    .pixel-accordion-expand-icon-more {
        display: none;
        transition: 0.3s;
    }
    .pixel-accordion-expand-icon-less {
        display: inline-block;
        transition: 0.3s;
    }
}
