@import "../../../assets/mixins.scss";
@import "../../../assets/colors.scss";

#family-component-body-container {
    height: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;

    .MuiFormLabel-filled {
        background: white !important;
        color: #006fb2 !important;
    }

    .MuiFormLabel-root:not(.MuiInputLabel-shrink) {
        color: #006fb2 !important;
    }
    .MuiInputLabel-outlined {
        transform: translate(14px, 10px) scale(1) !important;
    }

    .MuiFormLabel-root.Mui-focused {
        background: white !important;
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(14px, -10px) scale(0.8) !important;
    }

    .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
        padding-top: 0;
        padding-bottom: 0;
    }
    .MuiInputBase-root.MuiOutlinedInput-root:not(.Mui-focused)
        .MuiOutlinedInput-notchedOutline {
        border: 1px solid #006fb2;
        transition: 0.5s;
    }

    .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused
        .MuiOutlinedInput-notchedOutline {
        border: 1px solid #006fb2;
        transition: 0.5s;
    }

    .MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input.MuiAutocomplete-inputFocused.MuiInputBase-inputAdornedEnd.MuiOutlinedInput-inputAdornedEnd {
        font-weight: bold;
    }
}
