@import "../../../assets/colors.scss";
@import "../../../assets/mixins.scss";

#pam-canalisation-header-container {
    height: 5em !important;
    min-height: 5em !important;
    overflow: hidden !important;
    #colored-header-band {
        height: 5px;
        background: blue; /* For browsers that do not support gradients */
        background: linear-gradient(to right, lightgray, blue, lightgray);
    }

    #logo {
        height: 3.3em !important;
    }

    .lang-btn {
        height: 2em !important;
        img {
            height: 2em !important;
        }
    }

    #display-name-div {
        font-size: 1.2em;
        color: $SG_PAM_BLUE; // defined in assets/color.scss
    }

    #header-saved-projects-button:disabled {
        //background-color: $SG_PAM_LIGHT_ORANGE;
        color: gray;
    }
}
