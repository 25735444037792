.ui.image.center-img {
    margin: 0 auto 10px auto !important;
}

.error-management-modal .btn-ok {
    box-shadow: 0 0 0 0 rgb(34 36 38 / 15%) inset;
    font-size: 1rem;
    background-color: #0d71bb;
    color: #fff;
    text-shadow: none;
}

.error-management-modal .btn-cancel {
    margin: 5px;
    font-size: 1rem;
    background-color: #f2711c;
    color: #fff;
    text-shadow: none;
    background-image: none;
    box-shadow: 0 0 0 0 rgb(34 36 38 / 15%) inset;
}

.ui.button.btn-cancel:hover {
    background-color: #f26202;
    color: #fff;
    text-shadow: none;
}