
.PropertyGlobalContainer {
  margin-left: 20px;
  margin-right: 20px;
  overflow: hidden;
  height: 100%;
}

.PropertiesContainer {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  min-height: 330px;
  border-radius: 5px;
  padding: 8px;
  height: 80%;
  overflow: hidden;
  justify-content: center;
}

.PropertySelectContent {
  display: flex;
  flex-direction: row;
}

.PropertiesContent {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  overflow: "auto";
  height: 90%;
}

.Properties {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  min-height: 300px;
  overflow-y: auto;
  width: 38%;
  height: 100%;
}

.PropertyButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  // position: absolute;
  // bottom: 5px;
}

.PropertySelectContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.125);
  font-size: 14px;
  flex-basis: "33.33%";
  flex-shrink: 0;
  min-height: 40px;
  font-weight: 600;
  border-bottom: none;
}

.PropertySelectTitle {
  margin-top: 6px;
  margin-left: 10px;
}

.PropertySelectValue {
  margin-right: 14px;
  margin-top: 4px;
}

.PropertiesCircularProgress {
  align-self: center;
}

.PropertyButton {
  background-color: #2185d0 !important;
  border-radius: 3;
  border: 0;
  color: white !important;
  height: 48;
  padding: 0 30px;
  min-width: 200px;
}

.PropertyButtonLabel {
  text-transform: "capitalize" !important;
  font-weight: 400;
}

.PropertiesCircularProgressContainer{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}