.FilterButton {
  width: 210px;
  height: 45px;
  margin-bottom: 10px !important;
}

.FilterContainerNew {
  width: 27%;
  min-width: 240px;
  min-height: 50px;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-radius: 5px;
}

.FilterFavoriteContainer {
  display: flex;
  flex-direction: row;
}

.FilterContent:hover {
  overflow-y: scroll;
}

.FilterContent {
  overflow: hidden;
  height: 100%;
}

.Slider {
  width: 200px;
  padding-left: 10px;
}

.FilterName{
  font-size: 12px;
  font-weight: bold;
}

.SearchContainer{
  display: flex;
  flex-direction: row;
}

.FilterOption{
  width: 205px;
  margin-right: 5px;
}
