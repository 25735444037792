.LoadSystemGlobalContainer {
  margin-left: 20px;
  margin-right: 20px;
  height: 100%;
}

.LoadSystemContainer {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  min-height: 380px;
  border-radius: 5px;
  padding: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  height: 79%;
}

.LoadSystemButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}

.LoadSystemCardContainer {
  height: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: auto;
  padding: 10px;
}

.LoadSystemSearch {
  height: 30px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95%;
}

.LoadSystem {
  width: 73%;
  height: 100%;
}

.LoadSystemCircularProgress {
  align-self: center;
}

.SelectType {
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
}

.buttonLabel {
  font-size: 12px;
  font-weight: bold;
}

.button {
  text-transform: none;
}
