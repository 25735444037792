@import "../../../assets/colors.scss";

#warning-modal {
    .header {
        text-align: center;
        background-color: $SG_PAM_ORANGE;
        color: white;
    }

    .content {
        text-align: justify;
        padding: 1em !important;
        p {
            white-space: pre-line;
        }
    }

    .actions {
        text-align: center;
    }
}
