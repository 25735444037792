#wallSchedules-selectionLanding-container {
    height: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

#wallSchedules-groupSelector-container_Riggips {
    height: 100% !important;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
}

#wallSchedules-solutionMapping-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
}
