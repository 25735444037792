@import "../../../assets/mixins.scss";
@import "../../../assets/colors.scss";

#gtou-body-container {
    // height: 100% !important;
    overflow: hidden;
    div {
        height: 100% !important;
        p {
            text-align: justify;
        }
    }
    #terms-of-use-text-container {
        height: calc(100vh - 18.429em)!important;
        overflow-y: scroll;
        background-color: white;
        border: 1px solid $SG_PAM_ORANGE;
        padding: 2em 4em !important;
        // box-shadow: 0px 0px 8px 0px $SG_PAM_ORANGE;
        h5 {
            text-align: center;
            font-weight: bold;
        }
        p {
            margin-bottom: 0.5em;
            text-align: justify;
        }
    }
}
