@import "../../../assets/colors.scss";

#system-name-body-container {
    height: auto !important;

    #system-name-select-all-div {
        width: 30vw;
        margin: auto;
        // box-shadow: 0px 0px 8px 1px #ff8c00;
        border: 1px solid #ff8c00;
        // border-bottom: 2px solid #ff8c00;
        background: lightgray;
    }

    .item-selection-table-div {
        width: 100%;
        margin: auto;
        height: 19.5em;
        overflow-y: auto;
        // box-shadow: 0px 0px 8px 1px $SG_PAM_ORANGE;
        border: 2px inset darkgray;
        background: white;
        tr:hover {
            background: $SG_PAM_LIGHT_ORANGE;
            transition: 0.3s;
        }

        tr td {
            padding: 0.1em 1em;
        }
    }

    .MuiFormControl-root {
        width: 100% !important ;

        .MuiSelect-select:focus {
            background-color: transparent;
        }
        .MuiInputBase-input {
            font-weight: bold;
        }

        .MuiFormLabel-root:not(.MuiInputLabel-shrink) {
            color: $SG_PAM_ORANGE !important;
        }
        .MuiFormLabel-root.MuiFormLabel-filled {
            z-index: 1000;
        }
        .MuiInputLabel-shrink {
            background-color: $SG_PAM_BODY_BACKGROUND_COLOR !important;
            transform: translate(10px, 8px) scale(1) !important;
        }
        .MuiInputBase-root.MuiInput-underline.MuiInput-root {
            border: 2px solid $SG_PAM_GREEN;
            border-radius: 5px;
        }
        .MuiInput-underline:before,
        .MuiInput-underline:after {
            border: none !important;
        }
    }
}

// .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
//     margin: 0;
// }
