.fileUpload {
  .ui.input {
    input {
      cursor: pointer;
      word-wrap: break-word;
      line-height: 1em;
      white-space: normal;
      outline: 0;
      transform: rotateZ(0);
      min-width: 14em;
      min-height: 2.71428571em;
      background: #fff !important;
      display: inline-block;
      padding: 0.78571429em 2.1em 0.78571429em 1em;
      color: rgba(0, 0, 0, 0.87);
      box-shadow: none;
      border: 1px solid rgba(34, 36, 38, 0.15) !important;
      border-radius: 0.28571429rem;
      transition: box-shadow 0.1s ease, width 0.1s ease;
    }
  }
}
.hide {
  display: none;
}
.headerChk {
  display: inline-block !important;
  margin-right: 8px;
  align-items: center;
  min-height: 14px;
}
.productCont {
  height: calc(100vh - 150px);
  overflow-y: auto;
}
.noExcelCont {
  text-align: center;
  h4 {
    display: inline-block;
  }
}
.fullWidth {
  .ui.selection.dropdown {
    & > div {
      width: 100%;
    }
  }
}
.p-0{
      padding: 0 !important;
}
.p-l-0 {
  padding-left: 0 !important;
}
.product-selected{
  display: block;
}