/***** Button ****/
.large-primary-button {
    width: 46%;
    background-color: #2185d0;
    color: #fff;

    &:first-child {
        margin-right: 20px;
    }
    &.active{
        background-color: #2185d0 !important;
            color: #fff !important;
    }
}

/**** Table ****/
.quantifier-grid {
    padding: 15px 25px !important;
    height: calc(100% - 180px);
    width: 100% !important;
    border: 0 none;
    margin-top: 0;
}
.selectionTable {
    tbody {
            display: block;
            height: calc(100vh - 250px);
            overflow-y: auto;
            td{
                font-weight: bold;
                text-align: center !important;
                border: 0 none !important;
            }
        }
    
        thead,
        tbody tr {
            display: table;
            width: 100%;
            table-layout: fixed;
        }
    
        .sidescroll {
            overflow-x: scroll;
        }
    thead{
        th{
            padding: 5px !important;
            min-height: 25px;
            &.bg-blue-header{
                background-color: #2185d0;
                color:#fff;
            }
            &.text-center{
                text-align: center !important;
            }
        }
        tr.bg-light-blue{
            th{
                background-color: rgb(221, 231, 246);
            }
        }
    }
}
/*** Utility classes ****/
.space-top-10 {
    margin-top: 10px;
}
.space-top-0 {
    margin-top: 0px !important;
}
.space-bottom-0 {
    margin-bottom: 0 !important;
}
.width5 {
    width:5%;
}
table {
    tr{
        td{
                padding: 5px;
                    min-height: 25px;
        }
    }
}

.space-top-5 {
    margin-top: 5px !important;
}

/*** Header Style ***/

.header-component {
    padding: 0;
    border: 0 none;
    margin-top: 15px;
}

/**** Specific height for table body ****/

.height400 {
    height: calc(100vh - 240px);
    overflow-y: auto;
    border: 1px solid #ccc;
    width: 98%;
    .table {
        border: 0 none;
        border-bottom:1px solid rgba(34, 36, 38, .15)
    }
}

/*** media query ****/

@media only screen and (min-width: 1200px) {
    .ui.container {
        width: 95%;
    }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
    .ui.container {
        width: 95%;
    }
}