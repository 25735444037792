.pgNo {
  margin: 0 2px 0 2px !important;
}

.input-box {
  width: 50px !important;
  height: 20px !important;
  padding: 0px !important;
}

.highlight {
  background-color: lightgrey;
  pointer-events: none;
}

.noHighlight {
  color: #000000;
}

.hoverPointer {
  cursor: pointer;
}

.currentPage {
  width: 40px !important;
  height: 20px;
  padding: 0px !important;
  border-color: #1e70bf !important;
  text-align: center;
}