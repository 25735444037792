.Properties {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.updateSystems {
  height: 100%;
}

.PropertyGlobalContainer {
  margin-left: 20px;
  margin-right: 20px;
  overflow: hidden;
}

.PropertiesContent {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  overflow: "auto";
}

.PropertySelectContent {
  display: flex;
  flex-direction: row;
}

.UpdateSystemContainer {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-radius: 5px;
  padding: 8px;
  overflow: hidden;
  justify-content: center;
  height: calc(100vh - 120px);
  // min-height: 330px;
}

.PropertyButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}
.MuiBackdrop-root {
  top: 30px !important;
}
.PropertyButton {
  background-color: #2185d0 !important;
  border-radius: 3;
  border: 0;
  color: white !important;
  height: 48;
  padding: 0 30px;
  min-width: 200px;
}

.PropertyButtonLabel {
  text-transform: "capitalize" !important;
  font-weight: 400;
}

.PropertySelectContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.125);
  font-size: 14px;
  flex-basis: "33.33%";
  flex-shrink: 0;
  min-height: 40px;
  font-weight: 600;
  border-bottom: none;
}

.PropertySelectTitle {
  margin-top: 6px;
  margin-left: 10px;
}

.PropertySelectValue {
  margin-right: 14px;
  margin-top: 4px;
}

.CSVLinkButton {
  background-color: #21ba45 !important;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px !important;
  letter-spacing: 0.02857em;
  height: 36px;
  margin-right: 10px !important;
}
