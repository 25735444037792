@import "../../../../assets/colors.scss";

#range-item-tr-no-compatible-elemnts {
    .element-selection-element-text {
        border-bottom: 1px solid lightgray;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: 0.5s;
    }

    .element-selection-element-text:hover {
        // transform: translateX(-10px) scaleX(1.1) scaleY(1.1);
        border-bottom: 1px solid lightgray;
        background-color: white;
        overflow: unset !important;
        white-space: unset !important;
        text-overflow: unset !important;
        line-height: initial;
        text-align: center;
        position: inherit;
        font-weight: 600;
        padding: 2px;
        transition: 0.5s;
    }

    #loose-elements-download-pam-objects,
    #loose-elements-highlight-in-model-btn {
        border-radius: 40px;
        padding: 1.2em 1.3em;
        border: 2px solid $SG_PAM_ORANGE;

        img {
            filter: invert(62%) sepia(77%) saturate(2512%) hue-rotate(325deg)
                brightness(98%) contrast(98%);
        }
    }

    #loose-elements-download-pam-objects:hover,
    #loose-elements-highlight-in-model-btn:hover {
        background: $SG_PAM_ORANGE;
        img {
            filter: invert(1);
        }
    }
}
