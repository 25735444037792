.HoveredRow{
    padding: 5px;
      min-height: 25px;
      /* background: rgb(250, 250, 255); */
      display: flex;
      align-items: center;
      border-bottom: 0.5px solid white;
}
.HoveredRow:hover{
    background:rgb(221, 231, 246);
}
.HoveredRow .ui.checkbox input.hidden{
    z-index: 1;
}