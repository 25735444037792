#range-selection-validate-selection-button {
    background: transparent;
    border: 1px solid lightgreen;
    box-shadow: 0px 0px 3px 0px darkgray;
    transition: 0.3s;

    .ui.button {
        line-height: 0.5em;
    }
}
#range-selection-validate-selection-button:hover {
    box-shadow: 0px 0px 8px 1px darkgray;
    background: rgba(60, 179, 113, 0.4);
    transition: 0.3s;
}

#search-input {
    height: 1.5em;
    width: -webkit-fill-available;
    vertical-align: sub;
}
