$SG_PAM_BODY_BACKGROUND_COLOR: #eeeefa;

// $SG_PAM_BLUE: #17428c;

$SG_PAM_LIGHT_BLUE: #3f51b5;

$SG_PAM_ORANGE: #f67252;

$SG_PAM_BLUE: #005ab3;

$SG_PAM_LIGHT_BLUE: #4189d1;

$SG_PAM_LIGHT_ORANGE: #f7beb0;

$SG_PAM_GREEN: #3cb371;

$SG_PAM_GRADIENT_BLUE_GRAY: lightgray, blue, lightgray;

$SG_PAM_DARK_GRAY: #505050;
