@import "../../../assets/mixins.scss";
@import "../../../assets/colors.scss";

#finish-body-container {
    height: 100% !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    #finish-body-inner-container {
        height: 19em !important;
        width: 100% !important;
        overflow: hidden;
        border: 1px solid $SG_PAM_ORANGE;
        background: #fbfbfb;
        padding: 1em 1em 3em 1em !important;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        #check-mark {
            width: 2em;
            height: 2em;
            margin: 0.5em;
        }
        .finish-body-inner-container-header-text {
            font-size: 18px;
            font-weight: 500;
        }
        .finish-button {
            background-color: $SG_PAM_ORANGE;
            color: white;
            height: fit-content;
            display: flex;
            justify-content: space-evenly;
            padding: 0;
            border-radius: 5px;
            div {
                display: flex;
                #new-project-icon,
                #saved-projects-icon {
                    padding: 1em;
                    background: $SG_PAM_DARK_ORANGE;
                    width: 5em;
                    height: 5em;
                    border-radius: 5px;
                }
                .finish-button-inner-div {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 0.4em;
                    font-size: 18px;
                    width: 6em;
                    line-height: 1.3em;
                }
            }
        }
        .finish-button:hover {
            cursor: pointer;
            background-color: rgba(246, 114, 82, 0.5);
            div {
                #new-project-icon,
                #saved-projects-icon {
                    background-color: rgba(252, 91, 50, 0.5);
                }
            }
        }
    }
}
