.gridContainer {
  margin-top: 20;
  border: 1 solid lightgrey;
  text-align: "center" !important;
}

.btn {
  text-align: center;
  margin-top: 50px;
}

.header-alignment {
  background-color: #2185d0 !important;
  margin-bottom: 3px;
  color: white !important;
  text-align: center;
  font-size: 14px;
}

.button-margin {
  margin: 30px 0px 30px 0px !important;
}

.text-center {
  text-align: center;
}

.margin-bottom-60 {
  margin-bottom: 60px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
  margin-bottom: 85px !important;
}
 
.grid-container-reperage {
  border: 1px solid lightgrey;
  text-align: center;
  margin-top: 10px !important;
  padding: 0px !important;
}

.popover {
  position: absolute;
  z-index: 2;
}

.cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.swatch {
  padding: "5px";
  background: "#fff";
  border-radius: "1px";
  box-shadow: "0 0 0 1px rgba(0,0,0,.1)";
  display: "inline-block";
  cursor: "pointer";
}

/* .ui.dimmer {
  text-align: left !important;
  background-color: transparent !important;
} */

.content {
  background-color: "lightgrey" !important;
  font-size: "smaller" !important;
  padding: 0.3em 0.3em !important;
}

.ui.segment {
  padding: none !important;
}

.btndiv {
  background-color: white;
  margin-top: 10px;
  float: right;
}

.cardFloat {
  float: center;
  width: 250px !important;
}

.hidePopup {
  display: none;
}
.showPopup {
  display: block;
}

.github-picker {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.compact-picker {
  padding-top: 0px !important;
  width: auto;
}

.header-alignment {
  background-color: #2185d0 !important;
  margin-bottom: 3px;
  color: white !important;
  text-align: center;
  font-size: 14px;
}

.ui.image {
  margin-right: 50px;
}

.ui.header .sub.header {
  font-size: 1.04rem !important;
}

.swatch {
  padding: "5px";
  background: "#fff";
  border-radius: "1px";
  box-shadow: "0 0 0 1px rgba(0,0,0,.1)";
  display: "inline-block";
  cursor: "pointer";
}
.popover {
  position: "absolute";
  z-index: "2";
}
.cover {
  position: "left";
  top: "0px";
  right: "0px";
  bottom: "0px";
  left: "0px";
}
.placoCard{
  cursor: "pointer";
  margin: ".2em .2em",;
  margin-left: "5px",;
}
.withborder{
  border:2px solid rgb(100, 100, 255) !important;
}
.withoutborder{
  border:0 none
}
.parameter-check-container{
  height: 120px;
    overflow: hidden;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 10px 15px;
    margin-bottom:10px;
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .ui.container {
      width: 80%;
  }
}
@media only screen and (min-width: 1200px) {
  .ui.container {
      width: 80%;
  }
}