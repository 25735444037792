.ui.checkbox {
  display: flex !important;

}

.coverpage-table .ui.checkbox {
  width: auto;
  min-height: auto;
}

.coverpage-table .ui.checkbox label {
  min-width: 100%;
}

.ui.dimmer .ui.loader:after {
  border-color: black transparent;
}

.chkFields {
  display: block !important;
  margin-bottom: 5px;
  width: 100%;
}

.msgListPn {
  background-color: aliceblue;
  padding: 5px;
  font-style: italic;
  font-size: 12px;
  line-height: 18px;
}

.InfoHdrmsg,
.InfoFtrmsg {
  padding: 5px;
  font-size: 15px;
}

.noLi {
  list-style-type: none;
}

.dimmed.dimmable>.ui.visible.dimmer::-webkit-scrollbar-thumb,
.ui.active.dimmer::-webkit-scrollbar-thumb {

  background-color: rgba(255, 255, 255, 0.7) !important;
}

.dimmed.dimmable>.ui.visible.dimmer::-webkit-scrollbar,
.ui.active.dimmer::-webkit-scrollbar {
  width: 10px !important;
}