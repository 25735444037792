@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:ital,wght@0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700&display=swap');
.subHeader {
  margin-bottom: 2px;
  padding: 5px 0px !important;
  height: 30px !important;
  background-color: #2185d0 !important;
  color: white !important;
  text-align: center !important;
}

.mainContainer {
  margin-top: 10px;
  border: 1px solid lightgrey !important;
  padding-bottom: 50px;
}

.btnContainer {
  text-align: center !important;
  margin-top: 50px;
}

.ui.header .sub.header {
  display: block;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  line-height: 1.2em;
  color: rgb(14, 110, 184) !important;
  font-weight: bold;
}
.dimmerHeight30{
  height: calc(100vh - 30px) !important;
}
.fontBebasIcon{
  font-family: 'Bebas Neue', cursive;
  font-size: 20px;
    padding-left: 2px;
    font-weight: 600;
}
.cctp-uploder-table{
      word-break: break-word;
}