input[type="file"] {
  display: none;
}

.customfile-upload {
  cursor: pointer;
  color: rgb(14, 110, 184);
}

.bottomLink {
  padding: 20px 15px;
  text-align: left;
  cursor: pointer;
}

.table-head-cell {
  background-color: rgb(33, 133, 208) !important;
  text-align: center !important;
  color: white !important;
  padding: 0px !important;
}
