.LoadSystemGlobalContainer {
  margin-left: 20px;
  margin-right: 20px;
}

.CartSystemDetailsContainer {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  min-height: 380px;
  border-radius: 5px;
  padding: 8px;
  overflow: hidden;
  height: 80%;
}

.Button:disabled { /* Increase the specificity */
  color: white !important;
}*


.Button {
  background-color: #2185d0 !important;
}

.LoadSystemButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
}

.LoadSystemSearch {
  height: 30px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CartSystemDetails {
  width: auto;
}

.LoadSystemCircularProgress {
  align-self: center;
}
