.title {
  color: rgb(14, 110, 184);
  font-weight: bold;
  text-align: center;
}

.headerRow {
  padding: 5;
  height: 15;
  background-color: #2185d0;
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid white;
}

.headerCols {
  justify-content: center;
  font-weight: bold;
  align-items: center;
  display: flex;
  width: 100%;
  color: white;
  border: 0.2px;
  padding: 2px;
}

.tabl {
  width: 95%;
  border: 0.2px solid rgb(184, 184, 184);
  margin: auto;
}

.noDocs {
  padding: 2%;
  text-align: center;
  border: 0.2px solid rgb(184, 184, 184);
}

.savedHeader {
  font-weight: bold;
  color: #fff;
  padding: 5px;
  text-align: center;
}

.headerRows {
  padding: 5;
  height: 25;
  background-color: rgb(14, 110, 184);
  align-items: center;
  border-bottom: 0.5px solid white;
  margin: 0 35px;
}

.summaryHomeScreen {
  padding: 2%;
  text-align: center;
  height: 300px;
}

.btnCont {
  margin: 10px;
}
.btnCont button {
  width: 20%;
}

.tablCont {
  width: 95%;
  margin: auto;
}

.alignLeft {
  float: left;
  width: 33.33333%;
  text-align: left;
}
.alignCenter {
  float: left;
  width: 33.33333%;
  text-align: center;
}

.alignRight {
  float: right;
  /* width: 33.33333%; */
  text-align: right;
}

.modalBg {
  background-color: rgb(14, 110, 184);
  color: #fff !important;
  align-items: center;
  height: 30px;
  line-height: 30px !important;
}

.selectionBtn {
  height: 70px;
  width: 70%;
}

.grid-container {
  border: 1px solid lightgrey;
  text-align: center;
  margin-top: 10px;
  padding: 65px;
}

i.icon.wrench:before {
  content: "\f0ad";
  padding: 5px;
  background-color: #d6d6d6 !important;
  color: grey;
  cursor: pointer !important;
}

.ui.image {
  margin-right: 5px !important;
}
