.error-msg {
    box-shadow: none !important;
    border: 0 none;
    margin: 0 !important;
    background-color: transparent !important;
    padding: 0 !important;
    color: red !important;
}
.gyproc-caliper-progress .content{
    width:100%;
}

.w-5{
    width:5%
}
.w-10{
    width:10%
}
.w-15{
    width:15%
}
.w-20{
    width:20%
}
.w-25{
    width:25%
}.w-15{
    width:15%
}
.w-30{
    width:30%
}
.w-35{
    width:35%
}
.step-process{
    background-color: #f1f1f1;
    padding: 10px;
}
.step-process .ui.checkbox:not(:last-child){
    margin-bottom: 10px;
} 