@import "../../../assets/colors.scss";

#range-selection-body-container {
    height: 100% !important;
    #range-selection-table-container {
        // box-shadow: 0 0 4px 1px $SG_PAM_ORANGE;
        border: 1px solid $SG_PAM_ORANGE;
        display: block;
        height: 25em;
        overflow-y: auto;
        padding: 0;
        thead tr th {
            position: sticky;
            top: 0;
            background-color: $SG_PAM_ORANGE;
            font-weight: 500;
            color: white;
            z-index: 1000;
            font-size: 13px;
            line-height: normal;
        }

        tbody {
            tr {
                height: 22.3em;
                border-bottom: 2px solid $SG_PAM_ORANGE;
            }
        }

        .range-component-td {
            width: 30%;
            max-width: 17em;
            overflow-x: auto;
            border-right: 2px solid lightgray;
            font-size: 16px;
            color: $SG_PAM_ORANGE;
        }

        .range-aviable-elements-td {
            width: 10%;
            max-width: 12em;
            overflow-x: auto;
            border-left: 2px solid lightgray;
            border-right: 2px solid lightgray;
            font-weight: bold;
            font-size: 18px;
        }

        .range-filters-selectors-td {
            width: 60%;
            max-width: 25em;
            overflow-x: auto;
            border-left: 2px solid lightgray;
            vertical-align: top;
        }
    }

    .inner-filter-angle-div {
        cursor: pointer;
        text-align: left;
        font-size: 12px;
        padding: 0px 1em;
        border-bottom: 2px solid lightgray;
        background-color: #e6e6e6;
    }

    .inner-filter-angle-div:hover {
        background-color: lightgray;
        transition: 0.5s;
    }

    .inner-filter-angle-diameter-div {
        text-align: left;
        font-size: 12px;
        padding: 0px 2em;
        font-weight: normal;

        input[type="checkbox"]:hover {
            cursor: pointer;
        }
    }

    .MuiButtonBase-root.MuiIconButton-root.PrivateSwitchBase-root-5.MuiCheckbox-root.MuiCheckbox-colorSecondary.MuiIconButton-colorSecondary {
        top: -1px;
    }

    .MuiFormLabel-root.MuiInputLabel-root {
        transform: translate(14px, 0px);
    }

    .MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
        transform: translate(9px, -12px) !important;
        background: #505050 !important;
    }

    .MuiInputBase-root.MuiOutlinedInput-root {
        padding: 0 !important;
        .MuiOutlinedInput-input {
            padding: 0 !important;
            padding-left: 1em !important;
            padding-right: 1em !important;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100% !important;
            color: white !important;
        }
    }

    input.MuiInputBase-input.MuiOutlinedInput-input::selection {
        color: black;
        background-color: whitesmoke;
    }

    .MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-filled {
        background: #505050 !important;
    }

    #range-selection-table-accordion-group {
        background-color: $SG_PAM_DARK_GRAY;
        color: white;
    }
}

// .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
//     margin: 0;
// }
