@import "../../assets/colors.scss";

#element-selection-container {
  height: 96% !important;
  // width: 99% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $SG_PAM_BODY_BACKGROUND_COLOR;
  border: 2px outset lightgray;
}
