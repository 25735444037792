@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import "../assets/colors.scss";

.bootstrap-scope {
    display: initial;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Roboto", sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.4);
}

.update-input {
    border: none;
    background: transparent;
    text-align: center;
    border-bottom: 1px solid $SG_PAM_ORANGE;
}
.update-input:focus {
    outline: none !important;
}

.icon-btn {
    border: none;
    background: none;
    height: auto;
    padding: 0;
    width: fit-content;
    // color: $SG_PAM_ORANGE;
}

.btn-red {
    color: $SG_PAM_ORANGE;
}

.btn-green {
    color: $SG_PAM_GREEN;
}

.medium-btn {
    border: none !important;
    box-shadow: none !important;
    background: none !important;
    height: 3em !important;
    padding: 0 !important;
    width: fit-content !important;
    img {
        height: 100% !important;
        width: auto !important;
    }
}

.xlarge-btn {
    border: none;
    background: none;
    height: 8em;
    padding: 0;
    width: fit-content;
    img {
        height: 100% !important;
        width: auto !important;
    }
}

img.round {
    border-radius: 5em;
}

img.elevated {
    // box-shadow: -1px 1px 5px 0.5px $SG_PAM_ORANGE;
    transition: 0.3s;
}
img.elevated:hover {
    // box-shadow: -1px 1px 10px 01px $SG_PAM_ORANGE;
    filter: drop-shadow(0px 0px 20px $SG_PAM_ORANGE);
    transition: 0.3s;
}

.color-sg-pam-orange {
    color: $SG_PAM_ORANGE;
}

// INPUTS
