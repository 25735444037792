@import "../../../assets/mixins.scss";
@import "../../../assets/colors.scss";

#project-name-body-container {
    height: auto !important;
    .MuiFormLabel-root:not(.MuiInputLabel-shrink) {
        color: gray !important;
    }
    .MuiInputLabel-shrink {
        background-color: transparent !important;
        transform: translate(10px, -25px) scale(1.2) !important;
        color: $SG_PAM_ORANGE !important;
    }

    .MuiInputBase-root.MuiOutlinedInput-root {
        .MuiOutlinedInput-notchedOutline {
            background: white !important;
        }
    }

    .MuiInputBase-root.MuiOutlinedInput-root:not(.Mui-focused) {
        .MuiOutlinedInput-notchedOutline {
            // border-color: $SG_PAM_ORANGE;
            // border-color: transparent;
            border: 1px solid gray;
            // box-shadow: 0px 0px 2px 2px $SG_PAM_ORANGE;
            transition: 0.3s;
        }
    }
    .MuiInputBase-root.MuiOutlinedInput-root input {
        z-index: 1000;
    }
    .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
            // border-color: $SG_PAM_ORANGE;
            // border-color: transparent;
            color: $SG_PAM_ORANGE;
            border: 1px solid $SG_PAM_ORANGE;
            // box-shadow: 0px 0px 2px 2px $SG_PAM_LIGHT_BLUE;
            transition: 0.3s;
        }
    }
    .MuiInputBase-root.MuiOutlinedInput-root {
        input {
            font-weight: bold;
        }
    }
    .MuiFormLabel-filled + div fieldset {
        // border-color: $SG_PAM_GREEN !important;
        border: 1px solid $SG_PAM_ORANGE !important;
        // box-shadow: 0 0 2px 2px $SG_PAM_GREEN !important;
        transition: 0.3s;
    }
}
