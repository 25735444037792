.modalActions {
  background-color: rgb(221, 231, 246) !important;
}

.modalButton {
  text-align: center;
  margin-top: 15px;
}
.modalHeader {
  background-color: rgb(14, 110, 184) !important;
  color: white !important;
  border-bottom: none !important;
  text-align: center;
  padding: 8px !important;
}
