.productImage {
  min-height: 90px;
  min-width: 90px;
  padding: 4px;
}

.profuctName {
  margin: auto;
  width: 60%;
  padding-top: 5px;
}

.loadCardContainer {
  max-width: 290px;
  margin-bottom: 10px;
  margin-left: 10px;
  max-height: 165.8px;
}

.loadActionContainer {
  display: flex;
  flex-direction: column;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.loadCardContent {
  height: 70px;
  max-height: 70px;
  padding-top: 2px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.loadActionIconContainer {
  margin-right: auto;
}

.loadActionButton {
  color: white !important;
  font-size: 14px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.placoCardActionButton {
  width: 50%;
}

.loadActionButtons {
  width: 100%;
}

.productImage {
  margin-right: 2px;
}

.yellow-color {
  background-color: #FAE100 !important;
}

.configurationActionButton {
  background-color: #21ba45 !important;
}

.light-grey-color {
  background-color: #A7A8AA !important;
}

.dark-grey-color {
  background-color: #646464 !important;
}

.black-color{
  background-color: #000000 !important;
}