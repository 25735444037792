@import "../../../assets/mixins.scss";
@import "../../../assets/colors.scss";

#data-transfert-body-container {
    height: 100% !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .orange-text {
        color: $SG_PAM_ORANGE;
        font-size: 16px;
    }

    .green-text {
        color: $SG_PAM_GREEN;
        font-size: 16px;
    }

    #data-transfert-body-lodaing-container {
        height: 19em !important;
        overflow: hidden;
        background-color: #fbfbfb;
        border: 1px solid $SG_PAM_ORANGE;
        padding: 2em 1em !important;
        // box-shadow: 0px 0px 8px 0px $SG_PAM_ORANGE;
        h5 {
            text-align: center;
            font-weight: bold;
        }
        p {
            margin-bottom: 0.5em;
            text-align: justify;
        }
        #saing-gobain-loading-gif {
            width: 25em;
        }
        .loader-div {
            margin: auto;
            .MuiLinearProgress-root.MuiLinearProgress-colorPrimary {
                background: white !important;
                height: 1em !important;
                border-radius: 5px;
                border: 1px solid darkorange;
                .MuiLinearProgress-barColorPrimary {
                    background: repeating-linear-gradient(
                        45deg,
                        rgba(246, 114, 82, 0.8),
                        rgba(246, 114, 82, 1) 2em,
                        rgba(246, 114, 82, 0.7) 2em,
                        rgba(246, 114, 82, 0.5) 4em
                    ) !important;
                }
            }
        }
        .loader-div-finished {
            .MuiLinearProgress-root.MuiLinearProgress-colorPrimary {
                border: 1px solid $SG_PAM_GREEN !important;
            }
        }
        .progress-percentage-div {
            position: relative;
            top: -1.2em !important;
            margin-left: auto;
            font-weight: 900;
            font-size: 14px;
            padding: 0px;
            margin-right: auto;
        }
    }
}
