body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.tableborderexport.fixed-table-header.table thead tr th {
  padding: 5px;
  height: 30px;
  background-color: #2185d0;
  color: white;
  border-bottom: 0.5px solid white;
  margin-top: 0em;
}

.ui.table {
  border: 0px;
}

.ui.button.floating.labeled.dropdown.icon.import-export-icon {
  background-color: transparent !important;
  padding: 0 !important;
  margin-right: 10px;

  &.active {
    background-color: #c0c1c2;
    background-image: none;
    box-shadow: 0 0 0 1px transparent inset;
    color: rgba(0, 0, 0, .95)
  }

  i {
    position: relative !important;
    width: 20px;
    background-color: transparent !important;
    font-size: 20px;
    color: #005eb8;
  }

  .menu.transition.visible {
    i {
      font-size: 14px;
      width: 14px;
    }
  }
}