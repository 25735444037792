

.container123 {
    display: flex;
    justify-content: space-between;
    width: 100px;
    margin: 0 auto;
    .circle {
      width: 40px;
      height: 33px;
      border-radius: 50%;
      background: linear-gradient(45deg,
        rgba(2,0,36,1) 0%,
        rgb(19, 66, 147) 35%,
        #2185d0 100%);
      box-shadow: inset 0 0 0 4px rgba(255,255,255,0.3);
      transform: translateX(0);
      z-index: 2;
      &:nth-child(1) {
        animation: move-1 2s infinite;
      }
      &:nth-child(3) {
        animation: move-3 2s infinite;
      }
    }
  }
  @keyframes move-1 {
    0%   {z-index: 3; transform: translateX(0);}
    25%  {z-index: 3; transform: translateX(80px);}
    50%  {z-index: 3; transform: translateX(0);}
    
    50.1%{z-index: 1; transform: translateX(0);}
    75%  {z-index: 1; transform: translateX(80px);}
    100% {z-index: 1; transform: translateX(0);}
  }
  @keyframes move-3 {
    0%   {z-index: 1; transform: translateX(0);}
    25%  {z-index: 1; transform: translateX(-80px);}
    50%  {z-index: 1; transform: translateX(0);}
    
    50.1%{z-index: 3; transform: translateX(0);}
    75%  {z-index: 3; transform: translateX(-80px);}
    100% {z-index: 3; transform: translateX(0);}
  }

.loadingText {
    font-style: oblique;
    font-size: 1.5em;
    padding-bottom: 10px;
    text-align: center;
}
.border-0 button{
  border:0 none !important
}