#select-all-checkbox-div {
    display: flex;
    justify-content: center;
    .MuiButtonBase-root {
        padding: 4px;
    }

    .Mui-checked {
        color: rgb(33, 133, 208) !important;
    }
}

#select-all-checkbox-div.checkbox-color-yellow {
    .Mui-checked {
        color: rgb(255, 224, 1) !important;
    }
}
