@import "../../../../../assets/colors.scss";

.inner-filter-angle {
    .pixel-accordion > .pixel-accordion-head {
        cursor: pointer !important;
        border: 1px solid lightgray;
        background-color: #e6e6e6;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 1em;
        margin: auto;
        transition: 0.3s;
    }
    .pixel-accordion > .pixel-accordion-head:hover {
        background-color: lightgray;
        color: black;
        transition: 0.3s;
    }
}
