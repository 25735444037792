@import "../../assets/colors.scss";

#terms-of-use-container {
  height: 100% !important;
  // width: 99% !important;
  display: flex;
  flex-direction: column;
  background: $SG_PAM_BODY_BACKGROUND_COLOR;
  border: 2px outset lightgray;
}
