.moveIcon{
    -webkit-user-select: none;
    -webkit-app-region: drag;
    -moz-user-select: none;
    user-select: none;
  }

  .noDrag{
    -webkit-app-region: no-drag;
  }
