/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.custom-ratio-types {

    .ui.radio.checkbox .box:after,
    .ui.radio.checkbox label:after {
        background-color: #2185d0 !important;
        top: 12px;
        left: -30px;
        width: 20px;
        height: 20px;
    }

    .ui.radio.checkbox .box:before,
    .ui.radio.checkbox label:before {
        width: 20px;
        height: 20px;
        left: -30px;
        top: 12px;
    }

    .ui.checkbox input.hidden + button {
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
    }

    input + label {
        background-color: #2185d0 !important;
        color: #fff !important;
        padding: .78571429em 1.5em .78571429em;
        border: 1px solid #2185d0;
        border-radius: 7px;
        box-shadow: 0 0 0 0 rgba(34, 36, 38, .15) inset;
        min-height: 1em;
        border-radius: .28571429rem;
        min-width: 260px;
    }
}

.height470 {
    height: calc(100vh - 193px) !important;
    overflow-y: auto;
}

.width10 {
    width: 10%;
}

.width15 {
    width: 15%;
}

.width20 {
    width: 20%;
}

.width25 {
    width: 25%;
}

.width30 {
    width: 30%;
}

.width35 {
    width: 35%;
}

.width40 {
    width: 40%;
}

.width45 {
    width: 45%;
}

.width50 {
    width: 50%;
}

.width55 {
    width: 55%;
}

.width60 {
    width: 60%;
}

.width70 {
    width: 70%;
}

.width80 {
    width: 80%;
}

.width90 {
    width: 90%;
}

.width100 {
    width: 100%;
}

.middel-full-width {
    width: 100%;
    justify-content: center;
    display: flex;
    margin-top: 30px;

    &.aligned-pagination {
        justify-content: space-between;

        .ratioPagination {
            padding-right: 0 !important;
            justify-content: flex-start;
            display: flex;

            .ui.pagination.menu {
                float: right;
            }
        }

        .rationButton {
            justify-content: flex-start !important;
            display: flex !important;
        }
    }
}

.ratioDeactiveCheckbox {
    background-color: #def1ffc2 !important;

    .ui.checkbox {
        label {
            color: #c5c1c1 !important;
        }
    }
}

.ratioDeactive {
    color: #c5c1c1 !important;
}

.ratio-input {
    padding: 0;
    height: 20px;
}

.text-white.ui.checkbox label {
    color: #ffffff !important
}