.gridContainer {
  margin-top: 20 !important ;
  border: 1px solid lightgrey !important;
  text-align: "center" !important;
}

.btn {
  text-align: center;
  margin-top: 50px;
}

.popover {
  position: absolute;
  z-index: 2;
}

.cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.swatch {
  padding: "5px";
  background: "#fff";
  border-radius: "1px";
  box-shadow: "0 0 0 1px rgba(0,0,0,.1)";
  display: "inline-block";
  cursor: "pointer";
}

.content {
  background-color: "lightgrey" !important;
  font-size: "smaller" !important;
  padding: 0.3em 0.3em !important;
}

.colorpicker {
  box-shadow: none !important;
}

.ui.segment {
  padding: none !important;
}

.btndiv {
  background-color: white;
  margin-top: 10px;
  float: right;
}

.cardFloat {
  float: center;
  width: 250px !important;
}

.hidePopup {
  display: none;
}
.showPopup {
  display: block;
}

.github-picker {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

/* .compact-picker {
  padding-left: 35px !important;
} */

.header-alignment {
  background-color: #2185d0 !important;
  margin-bottom: 3px;
  color: white !important;
  text-align: center;
}

.menuItemActive {
  background-color: #2185d0 !important;
  color: white !important;
  font-weight: bold !important;
}
.menuItemNotActive {
  background-color: #f0f0f0 !important;
  color: #a9a9a9 !important;
  font-weight: bold !important;
}

.ui.blue.menu .active.item {
  color: #ffffff !important;
}

.ui.menu {
  margin-left: 0px !important;
}

.productsubtype {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  padding: 0.78571429em 2.1em 0.78571429em 1em;
}

.ui.modal > .close {
  top: -2.5rem;
  right: -2.4rem;
  color: white;
}
.customize-token-input---container---32eEd {
  height: 40px;
  width:260px
}

.customize-token-input---container---32eEd.customize-token-input---focused---2EN4q {
  border-color:rgba(34,36,38,.35);
}

.customize-token-input---container---32eEd .customize-token-input---token-list---5WBoW .customize-token-input---token---2ASwp {
  line-height: 21px;
  color: none;
}

.customize-token-input---container---32eEd .customize-token-input---autosized-wrapper---33t7e input {
  height: 30px;
}

.DetailsContainer{
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-radius: 5px;
  padding: 8px;
  overflow: hidden;
  justify-content: center;
  height: calc(100vh - 160px) !important;
  min-height: calc(100vh - 185px);
  margin-top: 20px;
}

.img-center {
  display: block; 
  margin-left: auto; 
  margin-right: auto; 
  margin-top: auto; 
  margin-bottom: auto;
}

.SelectionTable {
  margin-bottom: -1rem !important;
}

.cloneIcon:hover{
  color: #67b9b0;
}

.editIcon:hover{
  color: #219cdc;
}

.trashIcon:hover{
  color:#ce1431;
}

.arrowicon:hover{
  color:#00448a;
}