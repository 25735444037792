.FunctionalityHeader {
  padding-bottom: 0px;
  padding-top: 0px;
  display: inline-flex;
}

.FunctionalityHeaderContainer {
  margin-bottom: 0px;
  text-align: center;
}
