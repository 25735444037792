@import "../../../assets/mixins.scss";
@import "../../../assets/colors.scss";

#pixel-table-container {
    // box-shadow: 0px 0px 15px -8px black;
    // border: 1px solid lightgray;
    div > div.MuiTableContainer-root {
        // height: 70vh;
        overflow: hidden;
        background: whitesmoke;
        min-height: 7em !important;
        max-height: 20em !important;
    }
    table thead {
        display: block;
        tr td {
            width: 70em;
            font-weight: 600;
            border-bottom: 1px solid lightgray;
            background: $SG_PAM_ORANGE;
            z-index: 1000;
            padding-top: 20px;
            padding-bottom: 10px;
        }
    }
    table tbody {
        cursor: pointer;
        max-height: 15em !important;
        min-height: 5em !important;
        overflow-y: auto;
        overflow-x: hidden;
        display: block;
        tr:hover {
            background: $SG_PAM_LIGHT_ORANGE;
            transition: 0.3s;
        }
        tr {
            transition: 0.3s;
            td {
                width: 70em;
                padding-top: 6px;
                padding-bottom: 6px;
                .MuiIconButton-root {
                    padding: 3px !important;
                }
                .MuiIconButton-colorPrimary {
                    color: $SG_PAM_ORANGE;
                    transition: 0.3s;
                }
                .MuiIconButton-colorPrimary:hover {
                    background-color: whitesmoke;
                    transition: 0.3s;
                }
            }
        }
    }
}

#pixel-table-container > div {
    box-shadow: none !important;
}

// .pixel-table-head {
//   font-weight: 600;
//   border-bottom: 1px solid lightgray;
//   background: whitesmoke;
// }

.pixel-table-footer {
    background: whitesmoke;
    border-top: 1px solid lightgray;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: $SG_PAM_ORANGE !important;
}

.pixel-table-pagination div {
    margin: 2px;
    svg {
        font-size: 2.2em;
        margin-top: -4px;
        cursor: pointer;
        transition: 0.3s;
    }
    svg:hover {
        background-color: lightblue;
        border-radius: 5px;
        color: gray;
    }
    span {
        padding: 2px;
        font-weight: 600;
        font-size: 16px;
        cursor: pointer;
        transition: 0.3s;
    }
    span:hover {
        background-color: lightblue;
        border-radius: 5px;
        color: gray;
    }
}

#pixel-table-pagination-current-page {
    color: $SG_PAM_ORANGE;
    span {
        font-size: 22px;
    }
}

.MuiFormLabel-root:not(.MuiInputLabel-shrink) {
    color: whitesmoke !important;
}

.MuiInputLabel-marginDense {
    transform: translate(14px, 5px) scale(1) !important;
}

.MuiOutlinedInput-inputMarginDense {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}
.MuiInputLabel-shrink {
    transform: translate(10px, -12px) scale(1) !important;
    font-weight: 600 !important;
    background-color: $SG_PAM_ORANGE !important;
    padding-left: 4px !important;
    padding-right: 4px !important;
}

.MuiOutlinedInput-notchedOutline {
    // border: 1px solid whitesmoke !important;
    // border-color: whitesmoke !important;
    legend {
        display: none;
    }
}

.order-by-this-button {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    flex-direction: inherit;
    justify-content: flex-start;
}

.order-by-this-button-svg {
    margin-left: 2px;
    margin-right: 3px;
}
.MuiTableSortLabel-icon {
    color: white !important;
}

.MuiInputBase-root.MuiOutlinedInput-root:not(.Mui-focused) {
    .MuiOutlinedInput-notchedOutline {
        border: 1px solid rgba(0, 0, 0, 0.23);
        transition: 0.3s;
    }
}
.MuiInputBase-root:hover.MuiOutlinedInput-root:not(.Mui-focused) {
    .MuiOutlinedInput-notchedOutline {
        border: 1px solid black;
        transition: 0.3s;
    }
}
.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
        // border-color: $SG_PAM_ORANGE;
        border-color: 1px solid $SG_PAM_ORANGE !important;
        // box-shadow: 0px 0px 2px 2px $SG_PAM_LIGHT_BLUE;
        transition: 0.3s;
    }
}

.MuiFormLabel-filled {
    color: $SG_PAM_ORANGE !important;
    border: none !important;
    transition: 0.53s;
}

.MuiFormLabel-filled + div fieldset {
    border-color: 1px solid $SG_PAM_ORANGE !important;
    // box-shadow: 0 0 2px 2px $SG_PAM_GREEN !important;
    transition: 0.3s;
}

.MuiPaper-elevation1 {
    box-shadow: none;
}

#no-projects-tables-text {
    color: gray;
}
