.ui.segment {
  border: none;
  box-shadow: none;
  width: 100%;
  padding: 0em 1em 1em;
}

.tabMenu .menu {
  border: none !important;
}

.tabMenu .item {
  border: 1px solid #2185d0 !important;
  /* border-radius: 0px 0px 0px 0px !important; */
  background-color: white !important;
  color: #2185d0 !important;
  font-weight: bold;
  height: 20px !important;
  width: 60px !important;
  text-align: center !important;
  margin-top: 12px !important;
}

.tabMenu .active.item {
  background-color: #2185d0 !important;
  color: white !important;
  box-shadow: none;
}
