input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid rgba(34, 36, 38, 0.15);
  display: inline-block;
  padding: 12px 5px;
  cursor: pointer;
  height: 2.71428571em;
  border-radius: 0.28571429rem;
}

.align-center {
  text-align: left;
  max-width: 190px;
  font-style: italic;
  color: red;
  word-break: break-word;
}

.modalButtonCCTP {
  text-align: center;
  padding: 15px;
  background: #f9fafb;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}