.ui.green.header {
  color: rgb(112, 173, 71) !important;
  & > .image:not(.icon),
  .ui.header > img {
    width: auto;
  }
  .sub.header {
    color: rgb(112, 173, 71) !important;
  }
}
.facadeF4 {
  .headerRow {
    background-color: rgb(112, 173, 71) !important;
    border-top-color: #70ad47 !important;
    border-bottom-color: #70ad47 !important;
  }
  .headerCols {
    border-right: 1px solid #fff;
  }
  .row,.column {
    display: flex;
    align-items: center;
  }
  .column{
    padding: 5px 10px;
    border-bottom: 1px solid #ccc;
    border-right:1px solid #ccc;
  }
  .column:last-child{
    border-right:0 none
  }
}
.facadeIcon {
  &.green.icon {
    color: rgb(112, 173, 71) !important;
  }
}
.FacadeModal {
  .modalHeader {
    background-color: rgb(112, 173, 71) !important;
  }
  .modalButton {
    justify-content: center;
  }
}
.ui.green.button,
.ui.green.buttons .button {
  background-color: rgb(112, 173, 71) !important;
}
.header-alignment.subHeader.green {
  background-color: rgb(112, 173, 71) !important;
}
i.green.icon {
  color: rgb(112, 173, 71) !important;
}
[disabled] {
  opacity: 0.5;
}
.sea-green {
  color: #34bfb1;
}
.textRedCenter{
  input{
    color:#db2828 !important;
    text-align: center !important;
  }
}

.facade-checkbox-radio .ui.checkbox .box:after,.facade-checkbox-radio .ui.checkbox label:after,
.facade-checkbox-radio .ui.radio.checkbox .box:after,.facade-checkbox-radio .ui.radio.checkbox label:after{
  top: 2px;
    width: 20px;
    height: 20px;
}
.facade-checkbox-radio .ui.radio.checkbox .box:after, .facade-checkbox-radio .ui.radio.checkbox label:after{
  top:1px;
  background-color: green;
}
.facade-checkbox-radio .ui.radio.checkbox input:checked~.box:after, .facade-checkbox-radio .ui.radio.checkbox input:checked~label:after,
.facade-checkbox-radio .ui.radio.checkbox input:focus:checked~.box:after, .facade-checkbox-radio .ui.radio.checkbox input:focus:checked~label:after{
  background-color: green;
}
.facade-checkbox-radio .ui.checkbox input:checked~.box:after, .facade-checkbox-radio .ui.checkbox input:checked~label:after,
.facade-checkbox-radio .ui.checkbox input:checked ~ .box:after,.facade-checkbox-radio .ui.checkbox input:checked ~ label:after
{
  color: green;
}
.facade-checkbox-radio .ui.checkbox input:checked~.box:before, .facade-checkbox-radio .ui.checkbox input:checked~label:before{
  border-color: green;
}
.facade-checkbox-radio .ui.checkbox .box:before, .facade-checkbox-radio .ui.checkbox label:before{
  border: 2px solid #d4d4d5;
  width: 20px;
    height: 20px;
}
.facade-checkbox-radio .ui.radio.checkbox .box:before, .facade-checkbox-radio .ui.radio.checkbox label:before {
  width: 20px;
    height: 20px;
}
.facade-checkbox-radio .ui.radio.checkbox input:checked~.box:after, .facade-checkbox-radio .ui.radio.checkbox input:checked~label:after{
  background-color: green;
}
.facade-checkbox-radio .ui.checkbox input:checked:focus~.box:before, .facade-checkbox-radio .ui.checkbox input:checked:focus~label:before, .context-container .ui.checkbox input:not([type=radio]):indeterminate:focus~.box:before, .ui.checkbox input:not([type=radio]):indeterminate:focus~label:before{
  border-color: green;
}

.context-container{
  .ui.grid{
    .row{
      padding-top:0;
      padding-bottom:0;
      .column{
        border: 1px solid #ccc;
        padding-top: 1rem;
        padding-bottom: 1rem;
          border-top: 0 none;
          border-left: 0 none;
          &:last-child{
            border-right:0 none;
          }
        }
        &:last-child {
          .column{
            border-bottom:0 none;
          }
        }
        &.light-green{
          background-color: rgba(112, 173, 71, .1);
          .column:nth-child(2){
            background-color: #ccc;
            border:1px solid #000;
          }
        }
      }
      
     
    }
  .checkbox-label-container{
    display: flex !important;
    strong{
      width: 10%;
    }
    span{
        width: 90%;
    }
  }
  .ui.checkbox{
    font-size: 12px;
    .box:before,label:before,.box:after,label:after{
      left: -5px;
    }
  }
  .ui.checkbox + .ui.checkbox {
    margin-top:10px;
  }
  .ui.input{
    margin-left: 40px;
    width: 80%;
    margin-top: 10px;
    input{
      text-align: right;
    font-style: italic;
    }
  }
  
}
.light-green{
  background-color: rgba(112, 173, 71, .1);
}
.left.aligned.five.wide.column.disabled-text {
  opacity: .6;
}
