.PropertyDetailsContainer {
  width: 60%;
  border: 1px solid rgba(0, 0, 0, 0.125);
  max-height: 100%;
  height: fit-content;
  overflow-y: auto;
}

.PropertyDetailsContent {
  max-height: 90%;
  height: fit-content;
}

.PropertyDetailsTitle {
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  height: 40px;
  padding: 8px 12px 0px;
  font-weight: 600;
}
