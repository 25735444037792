@import "../../../assets/mixins.scss";
@import "../../../assets/colors.scss";

#recap-container-body-container {
    height: auto !important;
    #recap-table-container {
        max-height: 25em;
        overflow-y: auto;
        border: 1px solid $SG_PAM_ORANGE;
        padding: 0;
    }

    #recap-table {
        border: 1px solid $SG_PAM_ORANGE;
        #recap-table-head {
            color: white;
            text-align: center;
            th {
                background: $SG_PAM_ORANGE;
                position: sticky;
                top: -1px;
                z-index: 99999;
            }
        }
        tbody {
            border-bottom: 2px solid $SG_PAM_ORANGE;
            tr:first-child td:first-child {
                background: white;
                color: $SG_PAM_ORANGE;
                text-align: center;
            }
            td {
                padding: 0.3em 1em;
                border-bottom: 1px solid rgba(200, 200, 200, 0.5);
                border-left: 1px solid rgba(200, 200, 200, 0.5);
                background: white;
            }
            td.background-gray {
                background: rgb(230, 230, 230);
            }
        }
    }
}
