@import "../../../assets/mixins.scss";
@import "../../../assets/colors.scss";

#project-menu-body-container {
    height: 100% !important;
    overflow: hidden;

    .MuiOutlinedInput-notchedOutline {
        background: whitesmoke;
    }

    .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMarginDense.MuiOutlinedInput-inputMarginDense {
        z-index: 100000;
        color: black;
    }
    .MuiInputLabel-shrink {
        display: none;
        transition: 2s;
    }
    .MuiFormLabel-root:not(.MuiInputLabel-shrink) {
        color: gray !important;
    }

    // display: flex;
    // flex-direction: column;
    // justify-content: flex-end;
    div {
        // height: 100% !important;
        p {
            text-align: justify;
        }
    }
    #project-menu-body-list-container {
        height: 95% !important;
        // max-height: 40vh;
        // overflow-y: scroll;
        // overflow-x: hidden;
        overflow: hidden;
        background-color: whitesmoke;
        border: 1px solid $SG_PAM_ORANGE;
        // box-shadow: 0px 0px 8px 0px $SG_PAM_ORANGE;
    }

    #new-project-btn:hover {
        border-radius: 10px;
        border: 1px solid $SG_PAM_ORANGE;
        // box-shadow: 0px 0px 12px 0px $SG_PAM_ORANGE;
        background: #eaeaea;
        transition: 0.3s;
    }

    #new-project-btn:focus {
        outline: none;
        transition: 0.3s;
    }
    #new-project-btn:active {
        background: $SG_PAM_ORANGE;
        color: white;
        transition: 0.3s;
    }

    #new-project-btn {
        border-radius: 10px;
        transition: 0.3s;
    }
    #add-btn-image {
        filter: invert(76%) sepia(34%) saturate(7495%) hue-rotate(326deg)
            brightness(98%) contrast(97%);
    }

    #warning-img {
        cursor: pointer;
        float: right;
        width: 1.5em;
        height: 1.5em;
        margin: 0.5em;
    }
    #warning-img:hover {
        filter: grayscale(80%);
    }
}
