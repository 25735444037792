.no-border {
  border: none !important;
  box-shadow: none !important;
}

.red-border [type~="input"],
.red-border [type~="select"],
.red-border [type~="date"],
.red-border [type~="file"] {
  border-color: red !important;
}

.formContainer {
  font-size: 10px !important;
  margin: 0 !important;
}

.inputCont .input {
  height: 30px !important;
}

.margin-10 {
  margin: 10px;
}
