#delete-selectionLanding-container {
    height: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

#delete-groupSelector-container {
    height: 100% !important;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
