#draw2-selectionLanding-container {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

#draw2-groupSelector-container {
  height: 100% !important;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#draw2-solutionMapping-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}
.ui.table td {
  padding:5px;
}
.ui.popup {
  font-size: 0.9rem;
}
.dropdown.product .text,
.dropdown.hanger-type .text {
  // min-width: 12rem;
  display: inline-block;
  // width:90%
}
@media screen and (min-width: 1200px) {
  .dropdown.product .text,
  .dropdown.hanger-type .text {
    min-width: 12rem;
    display: inline-block;
  }
  .dropdown.hanger-type .text {
    min-width: 10rem;
  }
}

@media screen and (max-width: 991px) {
  .dropdown.range .text {
    width: 35px;
  }
  .dropdown.product .text {
    width: 70px;
  }
  .dropdown.hanger-type .text {
    width: 48px;
  }
}

.ui.dropdown .menu > .item {
  line-height: 1.2em;
}

.ellipsis {
  overflow: hidden;
  height: 50px;
  line-height: 25px;
}
.ellipsis:before {
  content: "";
  float: left;
  width: 5px;
  height: 50px;
}
.ellipsis > *:first-child {
  float: right;
  width: 100%;
  margin-left: -5px;
}
.ellipsis:after {
  content: "\02026";
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  float: right;
  position: relative;
  top: -25px;
  left: 92%;
  width: 3em;
  margin-left: -3em;
  padding-right: 5px;
  text-align: right;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(255, 255, 255, 0)),
    to(white),
    color-stop(50%, white)
  );
  background: -moz-linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    white 50%,
    white
  );
  background: -o-linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    white 50%,
    white
  );
  background: -ms-linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    white 50%,
    white
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    white 50%,
    white
  );
}
.CLTConfirmTable {
        margin-bottom: 1rem;
  tr {
    th {
      background-color: #a5a5a5 !important;
      text-align: left !important;
      color: #fff !important;
      padding:0.58571429em 0.58571429em !important;
    }
      
  }  tr:nth-child(even) {

      background-color: #e1e1e1;

  }

  tr:nth-child(odd) {

      background-color: #f0f0f0;

  }
}
