#draw2-selectionLanding-container {
    height: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.rigipsContainer{
  display: block !important;
}

#draw2-groupSelector-container {
    height: 100% !important;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

#draw2-solutionMapping-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.react-swipeable-view-container [data-swipeable="true"] {
    overflow-y: hidden !important;
}
