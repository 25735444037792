@import "../../../assets/colors.scss";

#coupling-selection-body-container {
    height: 100% !important;
    max-height: 26em;
    overflow: hidden;
    #coupling-selection-table-container {
        height: 25em;
        overflow-y: auto;
        border: 1px solid $SG_PAM_ORANGE;
        padding: 0;
        table {
            width: 99.9% !important;
            height: 99.9% !important;
            thead {
                text-align: center;
                tr:first-child {
                    color: white;
                    th {
                        background: $SG_PAM_ORANGE;
                        top: 0;
                    }
                    // th:first-child {
                    //     width: 40%;
                    //     background-color: $SG_PAM_BLUE !important;
                    // }
                }
                tr:not(:first-child) {
                    th:first-child {
                        color: $SG_PAM_ORANGE;
                    }
                }
                tr {
                    th {
                        position: sticky;
                        z-index: 999999;
                        background: whitesmoke;
                        border-bottom: 1px solid $SG_PAM_ORANGE;
                        // box-shadow: 0 0 0 1px $SG_PAM_ORANGE;
                        select {
                            width: 70%;
                        }
                    }
                }
            }
            tbody {
                tr {
                    td {
                        border: 2px outset lightgray;
                        background: white;
                    }
                    td.incompatible-coupling {
                        background: repeating-linear-gradient(
                            135deg,
                            white,
                            white 4px,
                            darkgray 4px,
                            lightgray 6px
                        );
                    }
                    // td.incompatible-coupling.elementMatched {
                    //     background: repeating-linear-gradient(
                    //         135deg,
                    //         whitesmoke,
                    //         $SG_PAM_LIGHT_GREEN 4px,
                    //         darkgray 4px,
                    //         lightgray 6px
                    //     );
                    // }
                }
                tr:first-child {
                    td:not(:first-child) {
                        border: unset;
                        border: 1px solid gray;
                        // box-shadow: 0 0 0 1px gray;
                        background: whitesmoke;
                        position: sticky;
                        top: 75px;
                    }
                }
                tr:not(:first-child) {
                    td:first-child {
                        // background: $SG_PAM_LIGHT_ORANGE;
                    }
                    td:not(:first-child):not(.incompatible-coupling) {
                        cursor: pointer;
                        .selected-coupling img {
                            display: block !important;
                        }
                    }
                    td:not(:first-child):not(.incompatible-coupling):hover {
                        box-shadow: inset 0 0 0.5em darkgray;
                        transition: 0.3s;
                    }
                    // td:first-child:not(.incompatible-coupling):not(.selected-for-other-coupling).elementMatched {
                    //     background: $SG_PAM_LIGHT_GREEN;
                    // }
                    td:not(:first-child):not(.incompatible-coupling):not(.selected-for-other-coupling).selected-coupling {
                        .selected-coupling-img {
                            display: block !important;
                            transition: 0.3s;
                        }
                    }
                    td:not(:first-child, .selected-coupling).selected-for-other-coupling
                        .selected-for-other-coupling-img {
                        display: flex !important;
                        flex-direction: column;
                        justify-content: center;
                        text-align: center;
                        overflow-x: auto;
                        // background: whitesmoke;
                        // border: 1px solid gray;
                        font-weight: bold;
                        transition: 0.3s;
                        width: 20%;
                        height: 90%;
                    }
                    td:not(:first-child, .selected-coupling).incompatible-coupling.selected-for-other-coupling
                        .selected-for-other-coupling-img {
                        background: white;
                    }
                }
            }
        }

        .ui.selection.dropdown {
            padding: 0.3em;
            min-height: unset;
            min-width: 6em;
        }
        .dropdown.icon {
            padding: 0.3em;
        }

        .range-component-td {
            text-align: center;
            background: white;
            //position: fixed;
            width: 20%;
            //height: 12em;
            overflow-x: auto;
            border-right: 1px solid $SG_PAM_ORANGE;
            font-size: 16px;
            color: $SG_PAM_ORANGE;
            // div:first-of-type {
            //     z-index: 1;
            //     position: sticky;
            //     top: 365px;
            //     /* display: block; */
            //     padding: -1px;
            //     /* margin-top: 49px !important; */
            //     /* height: 159px; */
            //     height: 6em;
            //     width: 10em;
            // }
        }

        .MuiCheckbox-root {
            padding: 3px;
        }
    }
}
