.gridContainer {
  margin-top: 20;
  border: 1 solid lightgrey;
}

.btn {
  text-align: center;
  margin-top: 50px;
}

.header-alignment {
  background-color: #2185d0 !important;
  margin-bottom: 3px;
  color: white !important;
  text-align: center;
}

.button-margin {
  margin: 30px 0px 30px 0px !important;
}

.text-center {
  text-align: center;
}

.margin-bottom-60 {
  margin-bottom: 60px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.grid-container {
  border: 1px solid lightgrey;
  text-align: center;
  margin-top: 10px;
}

.margin-10 {
  margin: 0x !important;
}

body ::-webkit-scrollbar {
  width: 6px !important;
}

.updateTool {
  text-align: -webkit-center;
  margin: 5vh auto;
}

.list-item-black .header .ui.header {
  color: rgba(0, 0, 0, 0.87) !important;
}

.ui.green.header .ui.image {
  width: 50px !important;
}

.text-black {
  color: rgba(0, 0, 0, 0.87) !important;
}

.ui.table thead tr th.primary-header {
  padding: 5px;
  background-color: rgb(33, 133, 208);
  color: white;
  border-bottom: 0.5px solid white;
  text-align: left !important;
  display: block;
}

.ui.table thead tr.secondary-table-header th {
  padding: 5px;
  background-color: rgb(221, 231, 246);
  align-items: center;
  border-bottom: 0.5px solid white;
  color: rgba(0, 0, 0, .87);
}

.fixed_header tbody {
  display: block;
  width: 100%;
}
.fixed_header.height200 tbody{
  min-height: 200px;
}
.fixed_header.height400 tbody {
  max-height: calc(100vh- 230px);
}

.fixed_header thead tr {
  width: 100%;
    display: table;
}
.fixed_header tbody tr td{
padding:5px
}
.fixed_header tbody tr:last-child td {
border-bottom: 1px solid rgba(34, 36, 38, .1);
}

.fixed-table-header tbody {
  display: block;
  overflow: auto;
  min-height: 200px;
    height: calc(100vh - 180px);
  width: 100%;
}
.dossier-export-table.fixed-table-header tbody{
  height: calc(100vh - 220px);
}

.fixed-table-header thead tr,.fixed-table-header tbody tr {
  width: 100%;
  display: table;
}

.fixed-table-header tbody tr td {
  padding: 5px
}
.custom-file-uploader-button label{
        margin-bottom: 0 !important;
          padding: 10px;
          display: flex !important;
        align-items: center;
        justify-content: center;
}
.custom-file-uploader-button form{
      width: 100%;
        display: flex;
        justify-content: center;
}
.custom-file-uploader-button .modalButton{
  padding: 15px 0;
   margin: 0 15px !important;
}