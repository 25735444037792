@import "../../../assets/colors.scss";

#pam-footer-container {
    height: 4em !important;
    min-height: 4em !important;
    overflow: hidden !important;
    #footer-top-border-div {
        height: 2px;
        width: 90%;
        align-self: center;
        margin: auto;
        border-top: 1px solid lightgray;
    }

    #footer-container-div {
        height: 90% !important;
    }

    #header-saved-projects-button {
        background-color: $SG_PAM_ORANGE;
        color: white;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-self: center;
    }

    #header-saved-projects-button:disabled {
        background-color: darkgray;
        color: white;
    }

    #footer-next-button {
        background-color: $SG_PAM_ORANGE;
        color: white;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-self: center;
    }
    #footer-next-button:disabled {
        background-color: darkgray;
        color: white;
    }
    #footer-back-button {
        background-color: whitesmoke;
        color: $SG_PAM_ORANGE;
        border: 1px solid $SG_PAM_ORANGE;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-self: center;
    }
    #footer-back-button:disabled {
        background-color: $SG_PAM_LIGHT_ORANGE;
        color: gray;
    }
}
