.modalHeader {
  background-color: rgb(14, 110, 184) !important;
  color: white !important;
  border-bottom: none !important;
  text-align: center;
  font-size: 1.1em !important;
  padding: 8px;
}

.ui.modal > .header:not(.ui) {
  line-height: 1em !important;
}

.modalActions {
  text-align: center !important;
  overflow: auto;
}

.modalButton {
  text-align: center;
  /* margin: 10px 0px; */
  display: flex;
  padding-top: 5px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contentHeader {
  background-color: #2185d0;
  text-align: center;
  color: white;
  font-weight: bold;
  height: 15px;
}

.contentSegment {
  width: 100%;
}

.containerHeader {
  background-color: #2185d0 !important;
  color: white !important;
  padding: 5px 0px !important;
}

.ui.form input[type="file"] {
  border: none !important;
  border-radius: none !important;
  box-shadow: none !important;
  background: none !important;
}

.ui.menu {
  margin-left: 3.5em;
}

.dragRow {
  cursor: move;
  width: 63px;
}

.dragClass {
  background-color: #d7eff5;
}

.itemHide {
  display: none !important;
}

.docRow {
  width: 100%;
  padding: 5px;
  min-height: 25;
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid white;
  height: 30px;
}

.docRow:hover {
  background-color: rgb(221, 231, 246);
}

/* .iconCont {
  display: none;
} */

.rowVal {
  text-align: center;
  font-weight: bold;
  width: 38%;
  color: black;
  border: 0.2px;
  cursor: pointer;
}

/* .docRow:hover .iconCont {
  width: 10%;
  text-align: right;
  display: block;
} */

.inputDocRow {
  height: 10px;
  width: 350px !important;
}

.inputDocRow input {
  text-align: center !important;
}

.errorInput input {
  border: 1px solid red !important;
  background-color: #ffdada !important;
}
