@import "../../../assets/colors.scss";

#intro-body-container {
    height: auto !important;
    overflow: hidden;
    div {
        height: 100% !important;
        p {
            text-align: justify;
        }
    }
    #intro-body-intro-container {
        height: calc(100vh - 15.5em) !important;
        // overflow-y: scroll;
        // overflow-x: hidden;
        background-color: white;
        border: 1px solid $SG_PAM_ORANGE;
        // box-shadow: 0px 0px 8px 0px $SG_PAM_LIGHT_ORANGE;
    }
}
