.SearchSystems {
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
}

.SearchContainer {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}
