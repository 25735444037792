@import "../../../assets/colors.scss";
@import "../../../assets/mixins.scss";

#pam-header-container {
    height: 5em !important;
    min-height: 5em !important;
    overflow: hidden !important;
    #colored-header-band {
        height: 10px;
        background: red; /* For browsers that do not support gradients */
        background: -webkit-linear-gradient(left, blue, red); /* Safari  */
        background: -o-linear-gradient(right, blue, red); /* Opera */
        background: -moz-linear-gradient(right, blue, red); /* Firefox */
        background: linear-gradient(
            to right,
            blue,
            red
        ); /* Standard syntax (must be last) */
    }

    #logo {
        height: 3.3em !important;
    }

    .lang-btn {
        height: 2em !important;
        img {
            height: 2em !important;
        }
    }

    #display-name-div {
        font-size: 1.2em;
        color: $SG_PAM_ORANGE; // defined in assets/color.scss
    }

    #header-saved-projects-button:disabled {
        //background-color: $SG_PAM_LIGHT_ORANGE;
        color: gray;
    }
}
