#selection-checkbox-item-element {
    .element-selection-element-text {
        border-bottom: 1px solid lightgray;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: 0.5s;
    }

    .element-selection-element-text:hover {
        // transform: translateX(-10px) scaleX(1.1) scaleY(1.1);
        border-bottom: 1px solid lightgray;
        background-color: white;
        overflow: unset !important;
        white-space: unset !important;
        text-overflow: unset !important;
        line-height: initial;
        text-align: center;
        position: inherit;
        font-weight: 600;
        padding: 2px;
        transition: 0.5s;
    }

    .MuiCheckbox-root {
        padding: 4px !important;
    }
}
