.ui.checkbox .box:before,
.ui.checkbox label:before {
  border-radius: 0;
}

.selection.dropdown > div {
  width: 8vw;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  float: left;
  white-space: nowrap;
}

.ui.fluid.dropdown>.dropdown.icon{
    color: #7F7F7F;
    padding: 0;
    font-size: 2em;
    top: 0.5em;
}

.ui.fluid.dropdown {
    min-height: 1.5em;
    padding: 0.3em 2em 1em 0.5em;
    border-radius: 0;
}

.ui.celled.table tr th {
    text-align: center;
}
