@import "../../../assets/mixins.scss";
@import "../../../assets/colors.scss";

#csv-import-component-body-container {
    height: 100% !important;
    overflow: hidden;
    #filenames-div {
        border: 2px dashed blue;
        background: whitesmoke;
        height: 100% !important;
        max-height: 16em;
        min-height: 14em;
        overflow-y: auto;
        overflow-x: hidden;
        .file-name-div:hover {
            cursor: pointer;
            background: lightblue;
            font-weight: bold;
            transition: 0.5s;
        }
    }

    #filenames-div.file-drag-over {
        border: 4px dashed lightblue;
        filter: blur(5px);
    }
}
