.PropertyRowContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  font-size: 12px;
  align-content: center;
  flex-basis: "33.33%";
  flex-shrink: 0;
  min-height: 40px;
  padding-right: 10px;
}

.PropertyRowTitle {
  margin-top: 10px;
  margin-left: 10px;
  align-content: center;
  padding-right: 15px;
}

.PropertyRowValue {
  margin-top: 10px;
  margin-left: 10px;
  align-content: center;
  padding-right: 5px;
}
