#coupling-selection-modal {
    .content {
        padding: 1.5em 1em !important ;
    }
    .actions {
        padding: 0.5em 1em !important;
        button {
            padding: 0.5em 1em;
        }
    }
}
