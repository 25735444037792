@import "../../../assets/colors.scss";

#project-country-body-container {
    height: auto !important;

    .MuiFormControl-root {
        width: 100% !important ;

        .MuiSelect-select:focus {
            background-color: transparent;
        }
        .MuiInputBase-input {
            font-weight: bold;
            text-align: center;
        }

        .MuiFormLabel-root:not(.MuiInputLabel-shrink) {
            color: $SG_PAM_ORANGE !important;
        }
        .MuiFormLabel-root.MuiFormLabel-filled {
            z-index: 1000;
        }
        .MuiInputLabel-shrink {
            background-color: transparent !important;
            transform: translate(10px, -5px) scale(1.2) !important;
            color: $SG_PAM_ORANGE !important;
        }
        .MuiInputBase-root.MuiInput-underline.MuiInput-root {
            background: white;
            border: 1px solid $SG_PAM_ORANGE;
            border-radius: 5px;
        }
        .MuiInput-underline:before,
        .MuiInput-underline:after {
            border: none !important;
        }
    }
}

// .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
//     margin: 0;
// }
