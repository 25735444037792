.formContainer {
    height: calc(100vh - 170px);
    font-size: 10px !important;
    margin: 0 !important;
    overflow-y: auto;
    overflow-x: hidden;
}

.inputCont .input {
  height: 30px !important;
}

.hide-cursor.select-field .search:not(.active) input{
  color: transparent;
  text-shadow: 0 0 0 #0e0e0e;
} 

.text-center {
  text-align: center;
}

.btnLogo {
  display: inline-block;
  vertical-align: top !important;
  background-color: #ffffff !important;
}

.logoHolder {
  border: 1px solid #eeeeee;
  height: 35px;
  margin-right: 10px;
}

.divHide {
  display: none !important;
}

.colData {
  /* background-color: #cbeafd !important; */
  background-color: #e5f2f5 !important;
  padding: 10px !important;
  border: none !important;
}

.colData-header {
  border: none !important;
  padding-left: 0px !important;
  /* text-align: center !important; */
}
.m-t-0{
  margin-top: 0 !important;
}

.errDiv {
  color: red;
  text-align: center;
  vertical-align: top;
  margin-bottom: 10px;
}

.alert {
  color: red;
}

.font-weight-field {
  font-weight: bold;
}

.date-red-border {
  /* background: #fff6f6 !important; */
  border-color: red !important;
  color: #9f3a38 !important;
}

.date-inputCont {
  /* width: 380px !important; */
  height: 30px !important;
}
.react-datepicker-wrapper {
  display: block;
}

.field.inputCont.select-field .ui.selection.dropdown {
  min-height: initial !important;
  height: 30px;
}

.field.inputCont.select-field .text {
  top: -4px;
}
.field.inputCont.select-field.ui.search.dropdown > input.search {
  padding: 0.67857143em 1em;
}

.field.inputCont.select-field > .selection.dropdown > .dropdown.icon {
  padding: 0.55em;
}
